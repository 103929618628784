<template>
  <div>
    <div class="minHieght100 main-content mt-2 px-5 rounded-t-lg">
      <!-- Title -->
      <div class="flex gap-5 items-center py-10 rounded-md h-11">
        <label class="whitespace-nowrap">Form Title:</label>
        <div
          v-if="!isEditFormTitle"
          class="text-base text-label-text flex justify-between items-center w-full"
        >
          <div class="font-medium">
            {{ formTitle }}
          </div>
          <div class="cursor-pointer" @click="editFormTitle">
            <icon
              class="icon opacity-50 lg:opacity-100"
              color="white"
              icon="edit"
              height="15"
              width="15"
            />
          </div>
        </div>
        <div v-else class="flex justify-between items-center w-full gap-10">
          <UiInputBox
            v-model="formTitle"
            type="text"
            name="Form Title"
            title=""
            :no-error="true"
            label="Form Title"
            placeholder="Form Title"
            class="flex-1 w-full"
          />
          <div class="cursor-pointer" @click="editFormTitle">
            <icon class="icon opacity-50 lg:opacity-100" icon="check" height="15" width="15" />
          </div>
        </div>
      </div>
      <!-- Sections -->
      <div v-for="(section, index) in sections" :key="index" class="mt-10">
        <!-- Section Title -->
        <div
          v-if="isEditSectionTitle && index === currentSectionScope"
          class="flex justify-between"
        >
          <div class="w-1/2">
            <UiInputBox
              v-model="sections[index].title"
              type="text"
              name="Section Title"
              title=""
              :no-error="true"
              label="Form Title"
              placeholder="Enter section title"
              class="flex-1 mb-1"
            />
          </div>
          <div
            class="flex items-center justify-end gap-2 w-1/2 cursor-pointer"
            @click="saveSectionTitle"
          >
            <icon class="icon opacity-50 lg:opacity-100" icon="check" height="15" width="15" />
          </div>
        </div>
        <div v-else class="flex justify-between">
          <div class="flex gap-5 w-3/5">
            <div class="pl-3 py-3 pr-8 bg-primary-green text-white font-medium rounded-tr-lg">
              {{ sections[index].title ? sections[index].title : `Section ${index + 1}` }}
            </div>
            <div class="flex items-center cursor-pointer" @click="editSectionTitle(index)">
              <icon
                class="icon opacity-50 lg:opacity-100"
                color="white"
                icon="edit"
                height="15"
                width="15"
              />
            </div>
          </div>
          <div class="w-1/5 pl-5 flex items-center gap-2">
            <button
              class="focus:outline-none w-full focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
              @click="addQuestion(index)"
            >
              Add Question
            </button>
            <TableAction
              :idx="index"
              elipses="verticalElipses"
              :top="-15"
              :right="20"
              class="ml-1"
              :action-list="actionsListForQuestion"
              @action="questionActions"
            />
          </div>
        </div>
        <div>
          <div
            v-for="(question, idx) in section.data_form_questions"
            :key="idx"
            class="bg-primary-white p-5 rounded-md question border"
          >
            <div class="flex justify-end items-start gap-5">
              <div class="w-3/5 mb-5">
                <UiInputBox
                  v-model="section.data_form_questions[idx].title"
                  type="text"
                  name="Question"
                  title=""
                  :no-error="true"
                  label="Untitled Question"
                  placeholder="Untitled Question"
                  class="flex-1 w-full"
                />
                <div v-if="question.question_type === 'short'">
                  <UiInputBox
                    type="text"
                    name="Answer"
                    title=""
                    :no-error="true"
                    label=""
                    :disabled="true"
                    placeholder="Short Answer Text"
                    class="flex-1 w-full mt-5"
                  />
                </div>
                <div v-else-if="question.question_type === 'mcqs_question'">
                  <div class="mt-5">
                    <div
                      v-for="(option, optionIndex) in question.mcqs_options"
                      :key="optionIndex"
                      class="flex gap-4 items-center mb-5"
                    >
                      <div class="h-5 w-5 rounded-full border-2"></div>
                      <div>
                        <UiInputBox
                          v-model="question.mcqs_options[optionIndex].title"
                          type="text"
                          name="Question"
                          title=""
                          :no-error="true"
                          label=""
                          :is-not-translated="true"
                          :placeholder="`Option ${optionIndex + 1}`"
                          class="flex-1 w-full"
                        />
                      </div>
                    </div>
                    <div
                      class="flex items-center gap-3 mt-5 pl-0.5 cursor-pointer"
                      @click="addMcqOption(index, idx)"
                    >
                      <div>
                        <icon icon="plus" color="primary-green" width="16" height="16" />
                      </div>
                      <div class="flex items-end">Add Option</div>
                    </div>
                  </div>
                </div>
                <div v-else-if="question.question_type === 'date'">
                  <div class="mt-5">
                    <UiDatePicker :hide-title="true" />
                  </div>
                </div>
                <div v-else-if="question.question_type === 'time'">
                  <div class="mt-5">
                    <UITimePicker :hide-title="true" />
                  </div>
                </div>
                <div v-else-if="question.question_type === 'dropdown'">
                  <div class="mt-5">
                    <UiSingleSelect :options="question.mcqs_options" :hide-title="true" />
                  </div>
                  <div class="flex items-center gap-3 mt-5 pl-0.5 cursor-pointer">
                    <UiInputBox
                      v-model="dropdownOptionTitle"
                      type="text"
                      name="Option"
                      title=""
                      :no-error="true"
                      label=""
                      placeholder="Enter New Option"
                      class="flex-1 w-full"
                    />
                    <div @click="addDropdownOption(index, idx, dropdownOptionTitle)">
                      <icon icon="plus" color="primary-green" width="16" height="16" />
                    </div>
                  </div>
                </div>
              </div>
              <UiSingleSelect
                v-model="question.question_type"
                title=""
                label="title"
                :hide-title="true"
                :options="questionTypes"
                :clearable="false"
                class="w-2/5"
                placeholder="Question Type"
                reduce="question_type"
                @change="resetData(index, idx)"
              />
            </div>
            <div class="my-5 border border-border-line"></div>
            <div class="flex justify-end items-center gap-10">
              <div class="flex items-center gap-2">
                <div>Required</div>
                <ToggleButton
                  v-model="section.data_form_questions[idx].isRequired"
                  heading=""
                  :is-status-hide="true"
                />
              </div>
              <div>
                <TableAction
                  :idx="index"
                  elipses="verticalElipses"
                  :top="-15"
                  :right="20"
                  :action-list="actionsListForQuestion"
                  @action="questionActions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-10">
        <button
          class="focus:outline-none w-1/5 focus:shadow-md text-base text-white font-roboto h-12 flex justify-center items-center primary-button"
          @click="generateNewForm"
        >
          Generate Form
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UiInputBox from '@components/UiElements/UiInputBox.vue'
import icon from '@components/icons/icon.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState } from 'vuex'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UITimePicker from '@/src/components/UiElements/UiTimePicker.vue'

export default {
  components: {
    icon,
    UiInputBox,
    ToggleButton,
    UiSingleSelect,
    TableAction,
    UiDatePicker,
    UITimePicker,
  },
  data() {
    return {
      formTitle: '',
      isEditSectionTitle: false,
      isEditFormTitle: false,
      currentSectionScope: null,
      sections: [
        {
          title: '',
          data_form_questions: [
            { title: '', isRequired: false, question_type: 'short', mcqs_options: [] },
          ],
        },
      ],
      questionTypes: [
        { title: 'Short Question', question_type: 'short' },
        { title: 'Multiple Choice Question', question_type: 'mcqs_question' },
        { title: 'Date', question_type: 'date' },
        { title: 'Time', question_type: 'time' },
        { title: 'Dropdown', question_type: 'dropdown' },
      ],
      actionsListForSection: [{ name: 'Remove' }],
      actionsListForQuestion: [{ name: 'Remove' }],
      dropdownOptionTitle: '',
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    showModal: {
      handler() {
        this.addSection()
      },
    },
  },
  created() {
    this.formTitle = this.$route.params.title
    this.setRightbarData()
  },
  methods: {
    addQuestion(index) {
      let question = { title: '', isRequired: false, question_type: 'short', mcqs_options: [] }
      this.sections[index].data_form_questions.push(question)
    },
    addSection() {
      let section = {
        title: '',
        questions: [{ title: '', question_type: 'short', isRequired: false }],
      }
      this.sections.push(section)
    },
    addMcqOption(index, idx) {
      let option = { title: '' }
      this.sections[index].data_form_questions[idx].mcqs_options.push(option)
    },
    addDropdownOption(index, idx, title) {
      let option = { title: title }
      if (title) this.sections[index].data_form_questions[idx].mcqs_options.push(option)
      this.dropdownOptionTitle = ''
    },
    editSectionTitle(index) {
      this.isEditSectionTitle = true
      this.currentSectionScope = index
    },
    saveSectionTitle() {
      this.isEditSectionTitle = false
    },
    editFormTitle() {
      this.isEditFormTitle = !this.isEditFormTitle
    },
    questionActions(action, current) {
      switch (action) {
        case 'Delete':
          break
      }
    },
    resetData(index, idx) {
      this.sections[index].data_form_questions[idx].mcqs_options = []
    },
    generateNewForm() {
      this.generateForm({ title: this.formTitle, data_form_sections: this.sections })
    },
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Form',
          buttons: [
            {
              title: 'Add New Section',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('registration', ['generateForm']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" scoped>
.question:not(:first-child) {
  margin-top: 20px;
}
.question:first-child {
  border-top-left-radius: 0;
}
</style>
